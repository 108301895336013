/**
 * @module SalesFlow/evolved/view
 */

declare var $: JQueryStatic;
declare var _ddq: any;

import {Renderable} from 'view/renderable';
import Injector from 'core/injector';

export abstract class ViewEvolvedElementBaseClassVfPassOverlay extends Renderable<any> {

    protected _element: JQuery;

    protected _passList: any;

    public readonly _selector: string = 'nsf-vf-pass-overlay';

    constructor (injector: Injector) {
        super(injector);

        this._element = $('#' + this._selector);
        this._element.addClass('readonly');
    }

    public render () {

        if (undefined !== this._passList) {
            this.renderAccordionItems();
        } else {
            this.loadAppFile();
        }

        _ddq.push(['layover', {
            action: 'show',
            name: 'enjoy more'
        }]);

    }

    public events () {

        $(window).on('vf::overlayopened', (event: JQuery.Event, args) => {
            if (args.overlayId === this._selector) {
                this.render();
                setTimeout(function () {
                    $(this).trigger('vf::resize');
                }, 100);
            }
        });
    }

    public bind () {

        this.events();

    }

    private loadAppFile (): void {
        $.ajax({
            url: 'https://eweb4.vfd2-testnet.de/scripts/vodafonepass-applist.json',
            method: 'get',
            type: 'json'
        }).done((response) => {
            if (response.passlist) {
                this._passList = response.passlist;
                this.renderAccordionItems();
            }
        }).fail(() => {

        });
    }

    private renderAccordionItems (): void {
        this._element.find('.tg-item[data-kias-code]').each((index, elem) => {
            if (this._passList[$(elem).data('kias-code')]) {
                const pass = this._passList[$(elem).data('kias-code')];
                if (pass.apps && 0 < pass.apps.length) {
                    const $tilesWrapper = $(elem).find('.tg-body .tg-board .partnericon-tiles-wrapper');
                    $tilesWrapper.html('');
                    for (let i = 0; i < pass.apps.length; i++) {
                        $tilesWrapper.append(this._injector.getTemplates().render('partnericon_tile', pass.apps[i], 'partials'));
                    }
                }
            } else {
                $(elem).remove();
            }
        });
    }

}
