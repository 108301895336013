/**
 * @module SalesFlow/core
 */
import {
    BusinessTransactionContext,
    SalesChannelName,
    SubscriptionGroupName,
    IWinbackGroupMapping,
    ITariffDiscountMapping,
    OrderType,
    GigakombiVorteilName
} from 'core/ids';
import { SalesChannelNameBackend, BusinessTransactionContextBackend, RedPlusTypes } from '@vfde-frontend/gso';
import { NotificationType } from 'view-evolved/notifications/notification-box';

interface GigakombiDiscountsMapping {
    [index: string]: number[];
}

interface StringMap {
    [index: string]: GigakombiVorteilName;
}

interface SalesChannelMapping {
    [index: string]: SalesChannelNameBackend;
}

interface BtxMapping {
    [index: string]: BusinessTransactionContextBackend;
}

interface GKNotificationDataItem {
     headline: string;
     content: string;
     notificationType: NotificationType;
}

interface DiscountsTextMapping {
    [salesChannel: string]: {
        [vorteil: string]: {
            [tariffId: number]: string;
            default: string;
        };
    };
}

export class Constants {
    /**
     * Black week ids 2023
     */
    public static readonly BlackWeekUnlimitedForGM = 10220;
    public static readonly BlackWeekUnlimitedForGMY = 10221;
    public static readonly BlackWeekUnlimitedForGM2024 = 303;

    /**
     * OrderTypes
     */
    public static readonly OrderType_Voice: OrderType = 'voice';
    public static readonly OrderType_Fixed: OrderType = 'fixed';

    /**
     * Some SubscriptionIds to cover e.g. no Red for Easy or no SimOnly for Black
     */
    public static readonly EasyS_Id: number = 426;
    public static readonly EasyM_Id: number = 427;

    /**
     * Red 2020 Portfolio IDs
     */
    /*
    public static readonly RedXS_Id: number = 2195;
    public static readonly RedS_Id: number = 2196;
    public static readonly RedM_Id: number = 2197;
    public static readonly RedL_Id: number = 2198;
    public static readonly RedXL_Id: number = 2199;
    public static readonly RedXL_2017_Id: number = 502;*/
    // New SubscriptionIds for 2024 Portfolio
    /**
     * SubscriptionId of GigaMobil
     */
    public static readonly RedXS_Id: number = 10375;
    public static readonly RedS_Id: number = 10376;
    public static readonly RedM_Id: number = 10377;
    public static readonly RedL_Id: number = 10378;
    public static readonly RedXL_Id: number = 10379;
    public static readonly RedXL_2017_Id: number = 502;

    /**
     * SubscriptionIds of GigaMobil Young
     */
    public static readonly YoungXS_Id: number = 10340;
    public static readonly YoungS_Id: number = 10341;
    public static readonly YoungM_Id: number = 10342;
    public static readonly YoungL_Id: number = 10343;
    public static readonly YoungXL_Id: number = 10344;
    /**
     * GigaMobile 2022 Portfolio IDs
     * GigaMobil - Gruppe 2500
     * GigaMobil XS - Gruppe (2022) 2501
     * GigaMobil S - Gruppe (2022) 2502
     * GigaMobil M - Gruppe (2022) 2503
     * GigaMobil L - Gruppe (2022) 2504
     * GigaMobil XL - Gruppe (2022) 2505
     */

    /**
     * Red 2020 Portfolio IDs - ONLY for INLIFE SUB15/25 Tariffs!!!
     */

    public static readonly RedXS_Id_Sub15_25: number = 2284;
    public static readonly RedS_Id_Sub15_25: number = 2285;
    public static readonly RedM_Id_Sub15_25: number = 2286;
    public static readonly RedL_Id_Sub15_25: number = 2287;
    public static readonly RedXL_Id_Sub15_25: number = 2288;

    /**
     * Young 2020 Portfolio IDs
     */
   /*
    public static readonly YoungXS_Id: number = 1083;
    public static readonly YoungS_Id: number = 1084;
    public static readonly YoungM_Id: number = 1085;
    public static readonly YoungL_Id: number = 1086;
    public static readonly YoungXL_Id: number = 1087;*/

    public static readonly Black_Id: number = 115;

    /**
     * GigaMobile Young 2022 Portfolio IDs
     * GigaMobil Young - Gruppe 2539
     * GigaMobil Young S - Gruppe 2540
     * GigaMobil Young M - Gruppe 2541
     * GigaMobil Young L - Gruppe 2542
     * GigaMobil Young L+ - Gruppe 2543 - NOt offered in OLS
     * GigaMobil Young XL - Gruppe 2544
     */

    /**
     * SubscriptionId of RedPlus
     */
    public static readonly RedData_SimOnlyId: number = 2233;
    public static readonly RedAllnet_SimOnlyId: number = 2236;
    public static readonly RedKids_SimOnlyId: number = 2231;
    public static readonly RedBasic_SimOnlyId: number = 2538; // new Red Plus Basic [CO-31313]

    public static readonly RedData_MainId: number = 1896;
    public static readonly RedAllnet_MainId: number = 1898;
    public static readonly RedBasic_MainId: number = 2537; // new Red Plus Basic [CO-31313]
    public static readonly RedKids_MainId: number = 1897;

    public static readonly Gigakombi_RedData_Id: number = 1896;
    public static readonly Gigakombi_RedAllnet_Id: number = 1898;
    public static readonly Gigakombi_RedBasic_Id: number = 2537;
    public static readonly Gigakombi_RedKids_Id: number = 1897;

    public static readonly RedUnlimitedCharging_Id: number = 876;

    /**
     * RedPlus Types
     */
    public static readonly RedPlus_Allnet: RedPlusTypes = 'allnet';
    public static readonly RedPlus_Basic: RedPlusTypes = 'basic'; // new Red Plus Basic [CO-31313]
    public static readonly RedPlus_Data: RedPlusTypes = 'data';
    public static readonly RedPlus_Kids: RedPlusTypes = 'kids';

    /**
     * Family Card
     */
    public static readonly FAMILYCARD_S_SUBSCRIPTION_TYPE: RedPlusTypes = 'small';
    public static readonly FAMILYCARD_M_SUBSCRIPTION_TYPE: RedPlusTypes = 'medium';
    public static readonly FAMILYCARD_L_SUBSCRIPTION_TYPE: RedPlusTypes = 'large';

    /**
     * RedPlus Types for VLUX Data Device JSON files
     */
    public static readonly RedPlus_Allnet_Vlux_File_Extension = 'redplusallnet';
    public static readonly RedPlus_Data_Vlux_File_Extension = 'redplusdata';
    public static readonly RedPlus_Kids_Vlux_File_Extension = 'redpluskids';
    public static readonly RedPlus_Basic_Vlux_File_Extension = 'redplusbasic'; // new Red Plus Basic [CO-31313]

    public static readonly FamilyFriendsType: string = 'family-friends';

    /**
     * SubscriptionGroupIds of supported SubscriptionGroups
     */
    public static readonly RedPlus_Group_Id: number = 1895;
    public static readonly Easy_Group_Id: number = 425;
    public static readonly Black_Group_Id: number = 138;
    public static readonly Red_Group_Id: number = 10374; // 2024 Portfolio
    public static readonly Red_New_Subs_Group_Id: number = 2283; // Just for Sub15/25 Inlife

    // Now we use Red XL tariff ID as there is no Red XL group ID in Red portfolio 2019
    public static readonly Unlimited_Group_Id: number = 1048;
    public static readonly Unlimited_2018_Group_Id: number = 874;

    public static readonly Young_Group_Id: number = 10339; // 2024 Portfolio
    public static readonly FamilyCard_Group_Id: number = 10043; // 2023 Portfolio

    /**
     * Available Business Transaction Contexts
     */
    public static readonly BTX_BNT: BusinessTransactionContext = 'bnt';
    public static readonly BTX_VVL: BusinessTransactionContext = 'vvl';
    public static readonly BTX_MULTISIM: BusinessTransactionContext = 'multisim';
    public static readonly BTX_HARDWARE: BusinessTransactionContext = 'hardware';
    public static readonly BTX_GIGAKOMBI: BusinessTransactionContext = 'gigakombi';
    public static readonly BTX_FAMILY_FRIENDS: BusinessTransactionContext = 'familyfriends';
    public static readonly BTX_INLIFE: BusinessTransactionContext = 'inlife';
    public static readonly BTX_REDPLUS: BusinessTransactionContext = 'redplus';

    /**
     * Available Sales Channels
     */
    public static readonly SALESCHANNEL_CONSUMER: SalesChannelName = 'consumer';
    public static readonly SALESCHANNEL_YOUNG: SalesChannelName = 'young';
    public static readonly SALESCHANNEL_SOHO: SalesChannelName = 'soho';
    public static readonly SALESCHANNEL_FAMILY_FRIENDS: SalesChannelName = 'familyfriends';

    /**
     * Available Sales Channels (Backend Notation)
     */
    public static readonly SALESCHANNEL_BACKEND_CONSUMER: SalesChannelNameBackend = 'Online.Consumer';
    public static readonly SALESCHANNEL_BACKEND_YOUNG: SalesChannelNameBackend = 'Online.Young';
    public static readonly SALESCHANNEL_BACKEND_SOHO: SalesChannelNameBackend = 'Online.SoHo';
    public static readonly SALESCHANNEL_BACKEND_FAMILY_FRIENDS: SalesChannelNameBackend = 'Online.FamilyFriends';

    public static readonly SalesChannelGeneralOfferObjectMapping: SalesChannelMapping = {
        consumer: Constants.SALESCHANNEL_BACKEND_CONSUMER,
        young: Constants.SALESCHANNEL_BACKEND_YOUNG,
        soho: Constants.SALESCHANNEL_BACKEND_SOHO,
        familyfriends: Constants.SALESCHANNEL_BACKEND_FAMILY_FRIENDS
    };

    /**
     * Available Business Transaction Contexts (Backend Notation)
     */
    public static readonly BTX_BACKEND_BNT: BusinessTransactionContextBackend = 'newContract';
    public static readonly BTX_BACKEND_VVL: BusinessTransactionContextBackend = 'contractProlongation';
    public static readonly BTX_BACKEND_MULTISIM: BusinessTransactionContextBackend = 'multicard';
    public static readonly BTX_BACKEND_HARDWARE: BusinessTransactionContextBackend = 'hardware';
    public static readonly BTX_BACKEND_GIGAKOMBI: BusinessTransactionContextBackend = 'gigakombi';
    public static readonly BTX_BACKEND_INLIFE: BusinessTransactionContextBackend = 'tariffChange';
    public static readonly BTX_BACKEND_REDPLUS: BusinessTransactionContextBackend = 'secondCard';

    public static readonly BtxGeneralOfferObjectMapping: BtxMapping = {
        bnt: Constants.BTX_BACKEND_BNT,
        vvl: Constants.BTX_BACKEND_VVL,
        multisim: Constants.BTX_BACKEND_MULTISIM,
        hardware: Constants.BTX_BACKEND_HARDWARE,
        gigakombi: Constants.BTX_BACKEND_BNT,
        familyfriends: Constants.BTX_BACKEND_BNT,
        inlife: Constants.BTX_BACKEND_INLIFE,
        secondcard: Constants.BTX_BACKEND_REDPLUS
    };

    /**
     * Available subscription groups
     */
    public static readonly SUBSCRIPTION_GROUP_CONSUMER: SubscriptionGroupName = 'consumer';
    public static readonly SUBSCRIPTION_GROUP_RED: SubscriptionGroupName = 'red';
    public static readonly SUBSCRIPTION_GROUP_YOUNG: SubscriptionGroupName = 'young';
    public static readonly SUBSCRIPTION_GROUP_EASY: SubscriptionGroupName = 'easy';
    public static readonly SUBSCRIPTION_GROUP_SOHO: SubscriptionGroupName = 'soho';
    public static readonly SUBSCRIPTION_GROUP_FF: SubscriptionGroupName = 'familyfriends';
    public static readonly SUBSCRIPTION_GROUP_IN: SubscriptionGroupName = 'in';
    public static readonly SUBSCRIPTION_GROUP_DATA: SubscriptionGroupName = 'data';

    /**
     * Single Tarif IDs
     */
    public static readonly REDXL_TARIF_IDS: number[] = [Constants.RedXL_Id, 1064, 1065, 1066, 1067, 1068, 1305];
    public static readonly REDL_TARIF_IDS: number[] = [Constants.RedL_Id, 1049, 1051, 1052, 1053, 1306, 1050];

    /**
     * Current Portfolio Subscription Group Ids
     */
    // @TODO:  is this used anymore??
    public static get CurrentSubscriptionGroupIdsOfCurrentPortfolio (): number[] {

        const ids: number[] = [
            this.Red_Group_Id,
            this.Red_New_Subs_Group_Id,
            this.Unlimited_Group_Id,
            this.Black_Group_Id,
            this.Young_Group_Id,
            this.Easy_Group_Id
        ];

        return ids;

    }

    public static get SubscriptionGroupIdsOfLatestPortfolio (): number[] {
        const ids: number[] = [
            this.Red_Group_Id,
            this.Red_New_Subs_Group_Id,
            this.Unlimited_Group_Id,
            this.Black_Group_Id,
            this.Young_Group_Id,
            this.Easy_Group_Id
        ];

        return ids;
    }

    /**
     * Redirect URL [VGOF-5450] [INC-22805]
     */
    public static readonly CUSTOMER_SALES_PAGE: string = 'eweb4.vfd2-testnet.de/privat';
    public static readonly CUSTOMER_SALES_MOBILE_PAGE: string = 'https://eweb4.vfd2-testnet.de/privat/mobilfunk.html';

    /**
     * SubscriptionIds to ignore
     *
     * Ignore this id because there more than one red XL tariff and we only want the unlimited group one
     */
    public static get Subscription_Ids_To_Ignore (): number[] {

        const ids: number[] = [
            this.RedXL_2017_Id
        ];

        return ids;

    }

    public static get AllRedAndAllYoungSubscriptionGroupIds (): number[] {

        const ids: number[] = [
            this.Red_New_Subs_Group_Id,
            this.Red_Group_Id,
            this.Young_Group_Id
        ];

        return ids;
    }

    public static get AllYoungSubscriptionGroupIds (): number[] {

        const ids: number[] = [
            this.Young_Group_Id
        ];

        return ids;
    }

    public static get AllYoungSubscriptionMainIds (): number[] {

        const ids: number[] = [
            this.YoungS_Id,
            this.YoungM_Id,
            this.YoungL_Id,
            this.YoungXL_Id
        ];

        return ids;
    }

    /**
     * Private Pricing Discount Ids
     * Red 2019 + 2020 PP Discount Ids
     */
    public static readonly PPDiscountIds: number[] = [1326, 1327, 1328, 1329, 1330, 1331, 2260, 2261, 2262, 2263];

    public static readonly Gigakombi_IP: number = 177;
    public static readonly Gigakombi_TV: number = 612;
    public static readonly Gigakombi_BR5: number = 618;
    public static readonly Gigakombi_Unity: number = 1637;

    /**
     * IP = DSL discount
     * TV = TV discount
     * BR5 = Discount provided on Kabel side, therefore less discount our side
     * IPandTV = Combination of DSL and TV
     * IPandBR5 = Combination of DSL and BR5
     * Unity = Unitymedia discount
     */
    public static readonly GigakombiDiscounts: GigakombiDiscountsMapping = {
            IP: [177],
            TV: [612],
            BR5: [618],
            IPandTV: [177, 612],
            IPandBR5: [177, 618],
            Unity: [1637]
    };

    public static readonly Giga11PromoIdRedM: number = 865;
    public static readonly Giga11PromoIdRedL: number = 866;
    public static readonly Giga11PromoIdYoungL: number = 867;

    public static readonly GigakombiUnlimitedPromoIdRedXS: number = 2618; // 2022 Portfolio
    public static readonly GigakombiUnlimitedPromoIdRedS: number = 2619; // 2022 Portfolio
    public static readonly GigakombiUnlimitedPromoIdRedM: number = 2246;
    public static readonly GigakombiUnlimitedPromoIdRedL: number = 2247;
    public static readonly GigakombiYoungSoc: number = 2252;

    public static readonly GigakombiCaseIP: GigakombiVorteilName = 'IP';
    public static readonly GigakombiCaseTV: GigakombiVorteilName = 'TV';
    public static readonly GigakombiCaseBR5: GigakombiVorteilName = 'BR5';
    public static readonly GigakombiCaseIPandTV: GigakombiVorteilName = 'IPandTV';
    public static readonly GigakombiCaseIPandBR5: GigakombiVorteilName = 'IPandBR5';

    public static readonly Data50Percent7Gb: number = 416;

    public static get GigakombiDiscountIDs (): number[] {

        const ids: number[] = [];

        for (const discounts in this.GigakombiDiscounts) {

            for (const id of this.GigakombiDiscounts[discounts]) {

                if (-1 !== ids.lastIndexOf(id)) {
                    continue;
                }

                ids.push(id);
            }

        }

        return ids;

    }

    /**
     * trade in discount Id
     */
    public static readonly TradeInDiscount_Id: number = 2367;
    public static readonly VVL_TradeInDiscount_Id: number = 2409;

    public static readonly TariffchangeRedXLSubLevelTariffIds: number[] = [851, 852, 853, 854, 855];

    /**
     * trade in tracking constants
     */
     public static readonly tradeinName: string = 'alt-gegen-neu-vorteil';
     public static readonly tradeinType: string = 'discount';
     public static readonly tradeinPriceMonthly: string = '10.00';
     public static readonly tradeinPoposition: number = 1;

    /**
     * Available Business Transaction Contexts
     */
    public static get AvailableBusinessTransactionContexts (): BusinessTransactionContext[] {

        const btxs: BusinessTransactionContext[] = [
            this.BTX_BNT,
            this.BTX_VVL,
            this.BTX_MULTISIM,
            this.BTX_HARDWARE,
            this.BTX_GIGAKOMBI,
            this.BTX_FAMILY_FRIENDS,
            this.BTX_INLIFE,
            this.BTX_REDPLUS
        ];

        return btxs;

    }

    /* @todo remove the headline and content from the gkNotificationData array
    *  after the CMS send the required data
    */
    public static readonly gkNotificationData: Partial<{[key in GigakombiVorteilName]: GKNotificationDataItem }> = {
        [Constants.GigakombiCaseIPandBR5]: {
            notificationType: 'info'
        },
        [Constants.GigakombiCaseIPandTV]: {
            notificationType: 'warning'
        },
        [Constants.GigakombiCaseTV]: {
            notificationType: 'warning'
        }
    };
    /**
     * Available Main Subscription IDs Contexts
     */
    public static get AvailableMainSubscriptionIDs (): number[] {

        return [
            this.RedXS_Id,
            this.RedS_Id,
            this.RedM_Id,
            this.RedL_Id,
            this.RedXL_Id,
    // IN LIFE RED6 SUB 15 & 25 portfolio
            this.RedXS_Id_Sub15_25,
            this.RedS_Id_Sub15_25,
            this.RedM_Id_Sub15_25,
            this.RedL_Id_Sub15_25,
            this.RedXL_Id_Sub15_25

        ];

    }

    public static readonly GigakombiVorteil: StringMap = {
        DSL: 'IP',
        TV: 'TV',
        Kabel: 'BR5',
        DSLandTV: 'IPandTV',
        DSLandKabel: 'IPandBR5',
        Unity: 'Unity'
    };

    public static get SubscriptionIdsToIgnoreInGigakombi (): number[] {
        return [
            this.YoungS_Id
        ];
    }

    /**
     * Eligable sales channel for winback landing pages
     * @TODO eligible
     */
    public static get EligibleSalesChannelForWinbackVoucher (): SalesChannelName [] {
        return [
           this.SALESCHANNEL_CONSUMER
         ];
       }

    /**
     * Winback Customers Group mapping
     */
    public static readonly WinbackGroup: IWinbackGroupMapping = {

        GROUP_A: '01_WEB_I_WBVCGA_R3_P',
        GROUP_C: '01_WEB_I_WBVCGC_R3_P'
     };

     /**
      * Group A Discounts Mapping. The tariff name is used as key to map it to the class name in the tariffs,
      * if changed here should be changed in the cms too.
      */
     public static readonly GroupADiscounts:  ITariffDiscountMapping = {

         RedXS: '5,00',
         RedS: '5,00',
         RedM: '2,50'
      };

     /**
      * Group C Discounts Mapping,The tariff name is used as key to map it to the class name in the tariffs,
      * if changed here should be changed in the cms too.
      */
     public static readonly GroupCDiscounts:  ITariffDiscountMapping = {

         RedXS: '2,50',
         RedS: '5,00',
         RedM: '2,50'
      };

    /**
     * win back service cases
     */
    public static readonly WINBACK_VOUCHER_REDEEMED: string = 'voucherredeemed';
    public static readonly WINBACK_VOUCHER_NOT_PART_OF_CAMPAIGN: string = 'notpartofcampaign';
    public static readonly WINBACK_VOUCHER_CAMPAIGN_OVER: string = 'campaignover';
    public static readonly WINBACK_VOUCHER_DEFAULT: string = 'genericmessage';
    public static readonly WINBACK_LANDING_PAGE: string = 'vvl_winback_landing_page';

    /**
     * pageStepTypes
     */
    public static readonly VVL_TARIF_DEVICE: string = 'vvl_tariff_device';
    public static readonly VVL_XSELL: string = 'vvl_x_sell';

    /**
     * mapping to hide mehr infos bullet  based on  sales channel , btx and gk cases
     */
    public static readonly HIDE_GK_MEHR_INFOS_BULLET_MAP = {
        [Constants.SALESCHANNEL_CONSUMER]: {
            [Constants.BTX_INLIFE]: {
                [Constants.GigakombiCaseTV]: [Constants.RedXS_Id, Constants.RedS_Id, Constants.RedM_Id, Constants.RedL_Id, Constants.RedXL_Id],
                [Constants.GigakombiCaseIP]: [Constants.RedXS_Id, Constants.RedS_Id],
                [Constants.GigakombiCaseIPandTV]: [Constants.RedXS_Id, Constants.RedS_Id],
                [Constants.GigakombiCaseIPandBR5]: [Constants.RedXS_Id, Constants.RedS_Id]
            }
        },
        [Constants.SALESCHANNEL_YOUNG]: {
            [Constants.BTX_INLIFE]: {
                [Constants.GigakombiCaseTV]: [Constants.YoungXS_Id, Constants.YoungS_Id, Constants.YoungM_Id, Constants.YoungL_Id, Constants.YoungXL_Id],
                [Constants.GigakombiCaseIP]: [Constants.YoungXS_Id, Constants.YoungM_Id],
                [Constants.GigakombiCaseIPandTV]: [Constants.YoungXS_Id, Constants.YoungM_Id],
                [Constants.GigakombiCaseIPandBR5]: [Constants.YoungXS_Id, Constants.YoungM_Id]
            }
        },
        [Constants.SALESCHANNEL_SOHO]: {
            [Constants.BTX_INLIFE]:
            {
                [Constants.GigakombiCaseTV]: [Constants.RedXS_Id, Constants.RedS_Id, Constants.RedM_Id, Constants.RedL_Id, Constants.RedXL_Id],
                [Constants.GigakombiCaseIP]: [Constants.RedXS_Id, Constants.RedS_Id],
                [Constants.GigakombiCaseIPandTV]: [Constants.RedXS_Id, Constants.RedS_Id],
                [Constants.GigakombiCaseIPandBR5]: [Constants.RedXS_Id, Constants.RedS_Id]
            },
            [Constants.BTX_VVL]:
            {
                [Constants.GigakombiCaseTV]: [Constants.RedXS_Id, Constants.RedS_Id, Constants.RedM_Id, Constants.RedL_Id, Constants.RedXL_Id],
                [Constants.GigakombiCaseIP]: [Constants.RedXS_Id, Constants.RedS_Id],
                [Constants.GigakombiCaseIPandTV]: [Constants.RedXS_Id, Constants.RedS_Id],
                [Constants.GigakombiCaseIPandBR5]: [Constants.RedXS_Id, Constants.RedS_Id]
            }
        }
    };

    /**
     * Handle discount text for Consumer, Young & Soho saleschannels
     */
    public static readonly HIGHLIGHT_BADGE_TEXT_MAPPING: DiscountsTextMapping = {
        [Constants.SALESCHANNEL_CONSUMER]: {
            // Handle discount text for consumer users with gigakombi cases ['TV']
            [Constants.GigakombiCaseTV]: {
                [Constants.RedXL_Id]: 'gigakombi.unlimited.discount.text',
                default: 'gigakombi.young-xs.discount.text'
            },
            // Handle discount text for consumer users with gigakombi cases ['IP', 'IPTV', 'BR5']
            [Constants.GigakombiCaseIP]: {
                default: 'gigakombi.discount.text'
            },
            [Constants.GigakombiCaseIPandBR5]: {
                default: 'gigakombi.discount.text'
            },
            [Constants.GigakombiCaseIPandTV]: {
                default: 'gigakombi.discount.text'
            }
        },
        [Constants.SALESCHANNEL_YOUNG]: {
            // Handle discount text for young users with gigakombi cases ['TV']
            [Constants.GigakombiCaseTV]: {
                [Constants.YoungXL_Id]: 'gigakombi.unlimited.discount.text',
                default: 'gigakombi.young-xs.discount.text'
            },
            // Handle discount text for young users with gigakombi cases ['IP', 'IPTV', 'BR5']
            [Constants.GigakombiCaseIP]: {
                [Constants.YoungXS_Id]: 'gigakombi.young-xs.discount.text',
                default: 'gigakombi.discount.text'
            },
            [Constants.GigakombiCaseIPandBR5]: {
                [Constants.YoungXS_Id]: 'gigakombi.young-xs.discount.text',
                default: 'gigakombi.discount.text'
            },
            [Constants.GigakombiCaseIPandTV]: {
                [Constants.YoungXS_Id]: 'gigakombi.young-xs.discount.text',
                default: 'gigakombi.discount.text'
            }
        },
        [Constants.SALESCHANNEL_SOHO]: {
            // Handle discount text for Soho users with gigakombi cases ['TV']
            [Constants.GigakombiCaseTV]: {
                [Constants.RedXL_Id]: 'gigakombi.unlimited.discount.text',
                default: 'gigakombi.soho-tv.discount.text'
            },
            // Handle discount text for Soho users with gigakombi cases ['IP', 'IPTV', 'BR5']
            [Constants.GigakombiCaseIP]: {
                [Constants.RedXL_Id]: 'gigakombi.discount.text',
                default: 'gigakombi.soho.discount.text'
            },
            [Constants.GigakombiCaseIPandBR5]: {
                [Constants.RedXL_Id]: 'gigakombi.discount.text',
                default: 'gigakombi.soho.discount.text'
            },
            [Constants.GigakombiCaseIPandTV]: {
                [Constants.RedXL_Id]: 'gigakombi.discount.text',
                default: 'gigakombi.soho.discount.text'
            }
        }
    };
}
