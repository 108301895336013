/**
 * @module SalesFlow/controller
 */

import { Constants } from 'core/constants';

import Subscription from 'model/type/subscription';
import AtomicDevice from 'model/type/atomic-device';
import DeviceOffer from 'view/view/shared/offer/device-offer';
import HardwareOnlyOffer from 'view/view/shared/offer/hardwareonly-offer';
import SimOnlyOffer from 'view/view/shared/offer/sim-only-offer';
import {BusinessTransactionContext, SalesChannelName} from 'core/ids';
import Pricebox from 'view/element/shared/pricebox';

import Injector from 'core/injector';
import GeneralSalesObjectInterface from 'service/general-sales-object/general-sales-object-interface';

import Offer from 'model/type/offer';
import {ViewEvolvedElementBntCmRedPlus} from 'view-evolved/element/bnt/view-evolved--element-bnt--cm-redplus';
import {ViewEvolvedElementBntCmRedPlusOhneHardware} from 'view-evolved/element/bnt/view-evolved--element-bnt--cm-redplus-ohne-hw';
import {ViewEvolvedElementVvlInsuranceDetailOverlay} from 'view-evolved/element/vvl/view-evolved--element-vvl--insurance-detail-overlay';
import CmAccessories from 'view/element/bnt/cm-accessories';
import CmOptionalService from 'view/element/bnt/cm-optional-service';
import Accessory from 'model/type/accessory';
import ViewAccessoryOffer from 'view/view/shared/accessory-offer';
import GigakombiDeviceDetailService from 'service/gigakombi/gigakombi-device-detail-service';

import {ViewEvolvedCtasSharedFlowCtas} from 'view-evolved/ctas/shared/view-evolved--ctas--shared--flow-ctas';
import {ControllerEvolvedFlowBaseWithoutSalesChannel} from 'controller-evolved/controller-evolved--flow-base-without-sales-channel';

import {ModelEvolvedRepoSupervisor} from 'model-evolved/repo/model-evolved--repo--supervisor';
import { ModelEvolvedRepoPurchasableDevice } from 'model-evolved/repo/model-evolved--repo--purchasable-device';

declare var $: JQueryStatic;

export class ControllerEvolvedBaseClassConnectMore extends ControllerEvolvedFlowBaseWithoutSalesChannel {

    private _salesChannel: SalesChannelName;
    private _atomicDeviceId: number;
    private _subscriptionId: number;
    private _generalDeviceRepo: ModelEvolvedRepoPurchasableDevice;

    protected _subscription: Subscription;
    protected _atomicDevice: AtomicDevice;
    protected _offer: Offer;
    protected _generalSalesObjectInterface: GeneralSalesObjectInterface;

    protected _deviceOffer: Offer;
    private _redplusAllnetDeviceOffers: DeviceOffer[] = [];
    private _redplusDataDeviceOffers: DeviceOffer[] = [];
    private _redplusBasicDeviceOffers: DeviceOffer[] = [];

    private _pricebox: Pricebox;

    constructor (
        salesChannel: SalesChannelName,
        atomicDeviceId: number,
        subscriptionId: number,
        reposSupervisor: ModelEvolvedRepoSupervisor,
        injector: Injector
    ) {
        super(
            reposSupervisor,
            injector
        );

        this._salesChannel = salesChannel;
        this._atomicDeviceId = atomicDeviceId;
        this._subscriptionId = subscriptionId;

        if (true === this.getInjector().getOptions().get('debug')) {
            const cnsl = console;
            cnsl.log('ControllerEvolvedBaseClassConnectMore....');
        }

        this._pricebox = new Pricebox(injector);
        this._generalSalesObjectInterface = this.getInjector().getGeneralSalesObjectInterface();
        this.setGeneralDeviceRepo();

        this.checkRedPlusOffers();

    }

    protected getGeneralSalesObject (): GeneralSalesObjectInterface {
        return this._generalSalesObjectInterface;
    }

    protected getSalesChannel (): SalesChannelName {
        return this._salesChannel;
    }

    protected createCtas (): ViewEvolvedCtasSharedFlowCtas {
        return undefined;

    }

    /**
     * INCM-466: In case, a tariffGroupChange has taken place after a Red+ was selected
     * on X-Sell page, we have to remove the Red+ preselection.
     * The reason is, that the offerIds are stored in session Storage and the offerIds
     * differ from tariffGroup to tariffGroup
     * @private
     */
    private checkRedPlusOffers (): void {

        const salesChannel = this.getSalesChannel();
        const allStorageItems = [
            this.getInjector().getFlowStateWithSalesChannel().redPlusAllnet,
            this.getInjector().getFlowStateWithSalesChannel().redPlusBasic,
            this.getInjector().getFlowStateWithSalesChannel().redPlusKids,
            this.getInjector().getFlowStateWithSalesChannel().redPlusData
        ];

        allStorageItems.forEach( (item) => {
            const offerId = item.elements[0];
            const redPlusOffer = this.getGeneralSalesObject().getRedPlusOfferById(
                offerId,
                salesChannel);
            if (undefined === redPlusOffer) {
                item.resetAllElements();
            }

        });

    }

    private fillOfferOfferCollection (): void {

        let subscription;
        let atomicDevice;
        let offer;

        if (undefined !== this.getSubscriptionId() && undefined !== this.getAtomicDevicId()) {
            /**
             * Hardware plus Tariff
             */

            subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(
                this.getSubscriptionId()
            );

            atomicDevice = this.getGeneralDeviceRepo().getAtomicDevice(
                this.getAtomicDevicId(),
                this.getSalesChannel(),
                subscription
            );

            offer = this._generalSalesObjectInterface.getSimHardwareOfferByAtomicDeviceIdAndSubscriptionId (
                atomicDevice.id,
                subscription.id,
                this._btx,
                this.getSalesChannel()
            );

        } else if (undefined !== this.getSubscriptionId() && undefined === this.getAtomicDevicId()) {
            /**
             * SIM Only
             */

            subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(
                this.getSubscriptionId()
            );

            offer = this._generalSalesObjectInterface.getSimOnlyOfferBySubscriptionId(
                this.getSubscriptionId(),
                this._btx,
                this.getSalesChannel()
            );

        }  else if (undefined === this.getSubscriptionId() && undefined !== this.getAtomicDevicId()) {
            /**
             * Hardware Only
             */

            subscription = undefined;

            atomicDevice = this.getGeneralDeviceRepo().getAtomicDeviceHardwareOnly(
                this.getAtomicDevicId(),
                this.getSalesChannel()
            );
            if (undefined === atomicDevice && ('' === window.document.referrer || -1 !== window.document.referrer.indexOf(Constants.CUSTOMER_SALES_PAGE))) {
                /**
                 * Changes [VGOF-5450] [INC-22805]
                 * Add condition to check if document referrer is empty and no atomic device is set
                 * to redirect the customer ` https://eweb4.vfd2-testnet.de/privat/mobilfunk.html`
                 */
                window.location.href = Constants.CUSTOMER_SALES_MOBILE_PAGE;
            }

            offer = this._generalSalesObjectInterface.getHardwareOnlyOfferByAtomicId(
                atomicDevice.id,
                this.getSalesChannel()
            );

        }

        // @TODO Great return ;) But what happens then
        if (undefined === offer) {
            return;
        }

        /**
         *  Did the user opts in a RedPlus Option
         */
        const offersRedPlus: any[] = [];
        const allRedPlusOptions  = [
            ...this.getInjector().getFlowStateWithSalesChannel().redPlusAllnet.elements,
            ...this.getInjector().getFlowStateWithSalesChannel().redPlusData.elements,
            ...this.getInjector().getFlowStateWithSalesChannel().redPlusKids.elements,
            ...this.getInjector().getFlowStateWithSalesChannel().redPlusBasic.elements
        ];
        if (0 < allRedPlusOptions.length) {
            const salesChannel = this.getSalesChannel();
            for (const offerId of allRedPlusOptions) {

                const redPlusOffer = this.getGeneralSalesObject().getRedPlusOfferById(
                    offerId,
                    salesChannel);
                const redPlusSubscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(
                    redPlusOffer.subscriptionId
                );

                if ('HmK' === redPlusOffer.offerType) {
                    let redPlusAtomicDevice: AtomicDevice;
                    if (Constants.RedData_MainId === redPlusSubscription.id) {
                        redPlusAtomicDevice = this.getReposSupervisor().getPurchasableTabletRepo().getAtomicDevice(
                            redPlusOffer.deviceId,
                            salesChannel,
                            redPlusSubscription
                        );
                    } else {
                        redPlusAtomicDevice = this.getGeneralDeviceRepo().getAtomicDevice(
                            redPlusOffer.deviceId,
                            salesChannel,
                            redPlusSubscription
                        );
                    }
                    offersRedPlus.push(new DeviceOffer(redPlusAtomicDevice, redPlusSubscription, redPlusOffer));
                } else {
                    offersRedPlus.push(new SimOnlyOffer(redPlusSubscription, redPlusOffer));
                }
            }
        }

        /**
         * Selected accessories
         */
        const accessoryOffers: ViewAccessoryOffer[] = [];
        for (const accessoryId of this.getInjector().getFlowState().accessoryIds.elements) {
            const atomicAccessory = this.getReposSupervisor().getPurchasableAccessoryRepo().getAtomicAccessory(accessoryId);
            const accessoryOffer = this.getInjector().getGeneralSalesObjectInterface().getHardwareOnlyOfferByAtomicId(
                accessoryId,
                this.getSalesChannel());
            accessoryOffers.push(new ViewAccessoryOffer(accessoryOffer, atomicAccessory));
        }

        /**
         * Get selected optional services
         */
        let optionalServiceIds = this.getInjector().getFlowStateWithSalesChannel().optionalServiceIds.elements;
        let activeOffer;

        if (true === this.getInjector().getFlowState().getHardwareOnly()) {
            activeOffer = new HardwareOnlyOffer(
                atomicDevice.getDevice().getAtomicDeviceById(offer.deviceId),
                offer,
                offersRedPlus,
                optionalServiceIds,
                accessoryOffers
            );
        } else {

            if (Constants.BTX_GIGAKOMBI === this._btx) {
                const gigakombiDeviceDetailService = new GigakombiDeviceDetailService(this.getInjector());
                optionalServiceIds = gigakombiDeviceDetailService.removeVFPassfromGKUnlimitedOffer(optionalServiceIds, subscription);
            }

            if (undefined === atomicDevice) {
                activeOffer = new SimOnlyOffer(
                    subscription,
                    offer,
                    offersRedPlus,
                    optionalServiceIds
                );

            } else {
                activeOffer = new DeviceOffer(
                    atomicDevice.getDevice().getAtomicDeviceById(offer.deviceId),
                    subscription,
                    offer,
                    offersRedPlus,
                    optionalServiceIds,
                    accessoryOffers
                );
            }
        }

        this.getInjector().getOfferCollection().setActiveOffer(
            activeOffer
        );

    }

    protected tracking () {
        this.getInjector().getEvent().trigger('pageviewTracking@onload',
            {
                deviceOffer: this.getInjector().getOfferCollection().getActiveOffer(),
                pageName: 'extras',
                pageType: 'product listing'
            }
        );
    }

    protected render (data?: any): void {

    }

    private getInsurance () {
        for (const serviceGroup of this._offer.getOptionalServices()) {
            for (const service of serviceGroup.services) {
                if (158 === service.id || 159 === service.id) {
                    return service;
                }
            }
        }

        return undefined;
    }

    private getAccessoryByID (id: number) {
        for (const accessoryGroup of this.getReposSupervisor().getPurchasableAccessoryRepo().getAccessories()) {
            for (const accessory of accessoryGroup.getAtomicAccessories()) {
                if (id === accessory.id) {
                    return accessory;
                }
            }
        }

        return undefined;
    }

    protected events (): void {

        this._injector.getEvent().listen('redPlusOffer@changed', (eventObject: JQueryEventObject, data: any) => {
            this.fillOfferOfferCollection();

            this.getInjector().getEvent().trigger('offer@changed', {
                offer: this.getInjector().getOfferCollection().getActiveOffer()
            });

        });

        this._injector.getEvent().listen('optionalServiceIds@changed', (eventObject: JQueryEventObject, data: any) => {
            this.fillOfferOfferCollection();

            this.getInjector().getEvent().trigger('offer@changed', {
                offer: this.getInjector().getOfferCollection().getActiveOffer()
            });

            const insurance = this.getInsurance();

            this.getInjector().getEvent().trigger('accessoryTracking@changed', {
                selected: data.selected,
                serviceName: insurance.label,
                type: 'service',
                backendId: insurance.backendId,
                priceMonthly: insurance.monthlyPrice.value
            });

        });

        this._injector.getEvent().listen('accessoryIds@changed', (eventObject: JQueryEventObject, data: any) => {
            this.fillOfferOfferCollection();

            this.getInjector().getEvent().trigger('offer@changed', {
                offer: this.getInjector().getOfferCollection().getActiveOffer()
            });

            const accessory = this.getAccessoryByID(data.accessoryId);

            this.getInjector().getEvent().trigger('accessoryTracking@changed', {
                selected: data.selected,
                serviceName: accessory.name,
                type: 'accessory',
                backendId: String(accessory.id),
                priceMonthly: '0.00'
            });

        });
    }

    protected getAtomicDevice (): AtomicDevice {

        return this.getGeneralDeviceRepo().getAtomicDevice(
            this.getAtomicDevicId(),
            this.getSalesChannel(),
            this._subscription
        );

    }

    protected getViewOffer (): Offer {

        const atomicDeviceId = this.getAtomicDevice().id;

        return this._generalSalesObjectInterface.getSimHardwareOfferByAtomicDeviceIdAndSubscriptionId (
            atomicDeviceId,
            this._subscriptionId,
            this._btx,
            this.getSalesChannel()
        );

    }

    protected updateStepper (): void {
        if (undefined !== this._atomicDevice) {
            const url = this.getAtomicDevice().getDevice().getDetailLink(this.getInjector().getOptions().get('device_detail_prefix'));
            this.getInjector().getEvent().trigger('stepper@device_detail_url', url);
        }

    }

    protected getRedAllnetOffer (): Offer {

        let redPlusOffer: Offer;
        if (0 < this.getInjector().getFlowState().redPlusAllnet.elements.length) {
            const redPlusId = this.getInjector().getFlowState().redPlusAllnet.elements[0];
            redPlusOffer = this.getGeneralSalesObject().getRedPlusOfferById(
                redPlusId,
                this.getSalesChannel());
        } else {
            redPlusOffer = this.getGeneralSalesObject().getRedPlusSimOnlyOfferByType(Constants.RedPlus_Allnet, this._salesChannel);
        }

        return redPlusOffer;
    }

    protected getRedBasicOffer (): Offer {

        let redPlusOffer: Offer;
        if (0 < this.getInjector().getFlowState().redPlusBasic.elements.length) {
            const redPlusId = this.getInjector().getFlowState().redPlusBasic.elements[0];
            redPlusOffer = this.getGeneralSalesObject().getRedPlusOfferById(
                redPlusId,
                this.getSalesChannel());
        } else {
            redPlusOffer = this.getGeneralSalesObject().getRedPlusSimOnlyOfferByType(Constants.RedPlus_Basic, this._salesChannel);
        }

        return redPlusOffer;
    }
    protected getRedDataOffer (): Offer {

        let redPlusOffer: Offer;
        if (0 < this.getInjector().getFlowState().redPlusData.elements.length) {
            const redPlusId = this.getInjector().getFlowState().redPlusData.elements[0];
            redPlusOffer = this.getGeneralSalesObject().getRedPlusOfferById(
                redPlusId,
                this.getSalesChannel());
        } else {
            redPlusOffer = this.getGeneralSalesObject().getRedPlusSimOnlyOfferByType(Constants.RedPlus_Data, this._salesChannel);
        }

        return redPlusOffer;

    }

    protected getRedKidsOffer (): Offer {

        let redPlusOffer: Offer;
        if (0 < this.getInjector().getFlowState().redPlusKids.elements.length) {
            const redPlusId = this.getInjector().getFlowState().redPlusKids.elements[0];
            redPlusOffer = this.getGeneralSalesObject().getRedPlusOfferById(
                redPlusId,
                this.getSalesChannel());
        } else {
            redPlusOffer = this.getGeneralSalesObject().getRedPlusSimOnlyOfferByType(Constants.RedPlus_Kids, this._salesChannel);
        }

        return redPlusOffer;

    }

    public bind (): void {

        this.fillOfferOfferCollection();
        this._stepper.bind();
        this._subscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(this.getSubscriptionId());
        const atomicDeviceId = this.getAtomicDevicId();

        const comingFromSailsPage = !!sessionStorage.getItem('SAILS_BNT');
        if (comingFromSailsPage) {
            $('body').addClass('sails-flow');
        }

        if (undefined !== this._subscription) {

            this._atomicDevice = this.getAtomicDevice();

            this._offer = this.getViewOffer();

            // Set Main Tariff Id for disolving Dependencies
            this.getGeneralSalesObject().redPlusOfferService.setMainTariffId(this._subscription.id.toString());

            /**
             * Start Red Plus Allnet
             */
            const redPlussAllnetOffer: Offer = this.getRedAllnetOffer();
            const redPlusAllnet = new ViewEvolvedElementBntCmRedPlus(
                this._injector,
                redPlussAllnetOffer,
                Constants.RedPlus_Allnet
            );
            this.initRedplusDeviceOffers(Constants.RedPlus_Allnet);
            redPlusAllnet.bind(this.getRowModuleDevices(Constants.RedPlus_Allnet));
            /**
             * End Red Plus Allnet
             */

            /**
             * Start Red Plus Basic
             */
            const redPlussBasicOffer: Offer = this.getRedBasicOffer();
            const redPlusBasic = new ViewEvolvedElementBntCmRedPlus(
                this._injector,
                redPlussBasicOffer,
                Constants.RedPlus_Basic
            );
            this.initRedplusDeviceOffers(Constants.RedPlus_Basic);
            redPlusBasic.bind(this.getRowModuleDevices(Constants.RedPlus_Basic));

            /**
             * End Red Plus Basic
             */

            /**
             * Start Red Plus Data
             */
            const redPlussDataOffer: Offer = this.getRedDataOffer();

            const redPlusData = new ViewEvolvedElementBntCmRedPlus(
                this._injector,
                redPlussDataOffer,
                Constants.RedPlus_Data
            );
            this.initRedplusDeviceOffers(Constants.RedPlus_Data);
            redPlusData.bind(this.getRowModuleDevices(Constants.RedPlus_Data));

            /**
             * End Red Plus Data
             */
            const redPlussKidsOffer: Offer = this.getRedKidsOffer();

            /**
             * Start Red Plus Kids
             */
            const redPlusKids = new ViewEvolvedElementBntCmRedPlusOhneHardware(
                this._injector,
                redPlussKidsOffer,
                Constants.RedPlus_Kids,
                this.getReposSupervisor()
            );
            redPlusKids.bind();
            /**
             * End Red Plus Kids
             */

            /**
             * Optional services
             * Loop over optional services and create an instance of CmOptionalService for each of them
             * For Soho we need to loop over all optional services, because the order changes
             */
            if (undefined !== this._offer && 0 !== this._offer.getOptionalServices().length) {
                for (const count in this._offer.getOptionalServices()) {
                    const optionalServices = this._offer.getOptionalServices()[count].services;
                    for (const index in optionalServices) {
                        /**
                         * Special case handy insurance: Service is already included in Red XL and Red XXL. Do not show option if one of these tariffs are selected
                         */
                        if (optionalServices[index].id === this._offer.getHandyInsuranceServiceId() && this._offer.hasHandyInsuranceIncluded()) {
                            continue;
                        }
                        const option = new CmOptionalService(this.getInjector(), optionalServices[index]);
                        option.bind();
                    }
                }
            }

            const insuranceDetailOverlay = new ViewEvolvedElementVvlInsuranceDetailOverlay(this._injector);
            insuranceDetailOverlay.bind();
        } else if (undefined !== atomicDeviceId) {
            this._atomicDevice = this.getGeneralDeviceRepo().getAtomicDeviceHardwareOnly(atomicDeviceId, this.getSalesChannel());
            if (undefined === this._atomicDevice && ('' === window.document.referrer || -1 !== window.document.referrer.indexOf(Constants.CUSTOMER_SALES_PAGE))) {
                /**
                 * Changes [VGOF-5450] [INC-22805]
                 * Add condition to check if document referrer is empty and no atomic device is set
                 * to redirect the customer ` https://eweb4.vfd2-testnet.de/privat/mobilfunk.html`
                 */
                window.location.href = Constants.CUSTOMER_SALES_MOBILE_PAGE;
            }

            this._offer = this._generalSalesObjectInterface.getHardwareOnlyOfferByAtomicId(
                this._atomicDevice.id,
                this.getSalesChannel()
            );
        }

        if (0 === $('.cm-redplus:visible').length && 0 === $('.cm-service:visible').length) {
            $('#no-tariffoptions').show();
        }

        if ( Constants.SALESCHANNEL_FAMILY_FRIENDS === this.getSalesChannel() ) {
            $('.cm-redplus').remove();
        }
        /**
         * Accessories
         */
        const accessories = this.getReposSupervisor().getPurchasableAccessoryRepo().getAccessories();
        if (0 < accessories.length) {
            const accessoriesModule = new CmAccessories(this._injector);
            accessoriesModule.bind(this.getAccessoryOffers(accessories));
        }
+
        this._pricebox.bind(this.getInjector().getOfferCollection().getActiveOffer());

        if (undefined !== this._ctas) {
            this._ctas.bind(this.getInjector().getOfferCollection().getActiveOffer());
        }

        this.updateStepper();

        this.render();

        /**
         * Bind Eevents
         */
        this.events();

        this.tracking();

        this.getInjector().getLoadingIndicator().hide();
    }

    protected getAtomicDevicId (): number {
        return this._atomicDeviceId;
    }

    protected getSubscriptionId (): number {
        return this._subscriptionId;
    }

    protected initData (): void {
        this._deviceOffer = this.getViewOffer();
    }

    /**
     *
     * @param type string [Constants.RedPlus_Allnet|Constants.RedPlus_Data]
     */
    private initRedplusDeviceOffers (type: string): void {
        let redPlusSubscription: Subscription;
        let devices;
        if (Constants.RedPlus_Data === type) {
            redPlusSubscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(Constants.RedData_MainId);
            devices = this.getReposSupervisor().getPurchasableTabletRepo().getDevices(
                this.getSalesChannel(),
                redPlusSubscription
            );
        } else if (Constants.RedPlus_Basic === type) {
            redPlusSubscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(Constants.RedBasic_MainId);
            devices = this.getReposSupervisor().getPurchasableDeviceRepo().getDevices(
                this.getSalesChannel(),
                redPlusSubscription
            );
        } else {
            redPlusSubscription = this.getReposSupervisor().getSubscriptionRepo().getSubscription(Constants.RedAllnet_MainId);
            devices = this.getReposSupervisor().getPurchasableDeviceRepo().getDevices(
                this.getSalesChannel(),
                redPlusSubscription
            );
        }

        const deviceOffers = devices.map(device => {
            const offer = this.getGeneralSalesObject().getSimHardwareOfferByAtomicDeviceIdAndSubscriptionId (
                device.getAtomicDeviceByIndex(0).id,
                redPlusSubscription.id,
                Constants.BTX_GIGAKOMBI === this._btx ? Constants.BTX_BNT : this._btx,
                this.getSalesChannel()
            );
             if (undefined !== offer) {
                return new DeviceOffer(device.getAtomicDeviceById(offer.deviceId), redPlusSubscription, offer);
            }
        });

        if (Constants.RedPlus_Data === type) {
            this._redplusDataDeviceOffers = deviceOffers;
        } else if (Constants.RedPlus_Basic === type) {
            this._redplusBasicDeviceOffers = deviceOffers;
        } else {
            this._redplusAllnetDeviceOffers = deviceOffers;
        }
    }

    private getRowModuleDevices (type: string): DeviceOffer[] {
        if (Constants.RedPlus_Allnet === type && 0 < this._redplusAllnetDeviceOffers.length) {
            return [...this._redplusAllnetDeviceOffers.slice(0, 8)
            ];
        }

        if (Constants.RedPlus_Basic === type && 0 < this._redplusBasicDeviceOffers.length) {
            return [
                ...this._redplusBasicDeviceOffers.slice(0, 8)
            ];
        }

        if (Constants.RedPlus_Data === type && 0 < this._redplusDataDeviceOffers.length) {
            return [
                ...this._redplusDataDeviceOffers.slice(0, 8)
            ];
        }

        return [];
    }

    /**
     * Returns an array of ViewAccessoryOffer for all atomic accessories
     * @param accessories Accessory[]
     * @returns viewAccessoryOffers ViewAccessoryOffer[]
     */
    private getAccessoryOffers (accessories: Accessory[]): ViewAccessoryOffer[] {
        const viewAccessoryOffers: ViewAccessoryOffer[] = [];

        for (const i in accessories) {
            if (accessories[i] instanceof Accessory) {
                const atomicAccessories = accessories[i].getAtomicAccessories();
                for (const j in atomicAccessories) {
                    const offer = this.getInjector().getGeneralSalesObjectInterface().getHardwareOnlyOfferByAtomicId(
                        atomicAccessories[j].id,
                        this.getSalesChannel());
                    if ('undefined' !== typeof offer) {
                        const accessoryOffer = new ViewAccessoryOffer(offer, atomicAccessories[j]);
                        viewAccessoryOffers.push(accessoryOffer);
                    }
                }
            }
        }

        return viewAccessoryOffers;
    }

    /**
     * sets the Purchasable Device Repo depending on ordertype
     */
    private setGeneralDeviceRepo () {
        this._generalDeviceRepo = this.getReposSupervisor().getConnectMoreDeviceRepo();
    }

    private getGeneralDeviceRepo (): ModelEvolvedRepoPurchasableDevice {
        return this._generalDeviceRepo;
    }
}
